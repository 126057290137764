.mainMemberListBox {
  background-color: #fff;
  padding: 60px 45px 50px 45px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.mainMemberListBox .headingBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
}

.mainMemberListBox .headingBox .title {
  font-family: "Graphik Medium";
  font-size: 16px;
  line-height: normal;
  font-style: normal;
  text-align: left;
  color: #06152b;
}

.mainMemberListBox .tableBox .table-responsive {
  overflow-x: visible !important;
  /* overflow-y: auto!important; */
}

/* .mainMemberListBox .tableBox button {
  background-color: transparent;
  border: none;
} */

.mainMemberListBox .tableBox .mail {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 0;
}

.mainMemberListBox .tableBox .date {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 0;
}

.mainMemberListBox .tableBox .code {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  line-height: 14px;
  color: #000000;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
}

.mainMemberListBox .tableBox thead th {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #000000;
  opacity: 0.5;
}

.mainMemberListBox .tableBox .emailTh {
  width: 23%;
}

.mainMemberListBox .tableBox .dateTh {
  width: 18%;
}

.mainMemberListBox .tableBox .codeTh {
  width: 14%;
}

/* .mainMemberListBox .tableBox .codeTd {
  width: fit-content;
}

.mainMemberListBox .tableBox .actionTd {
  width: fit-content;
} */

.mainMemberListBox .tableBox .table-responsive table tr td:nth-child(even),
.mainMemberListBox .tableBox .table-responsive table tr th:nth-child(even) {
  background-color: #fff;
}

.mainMemberListBox .tableBox .table-responsive table tr th,
.mainMemberListBox .tableBox .table-responsive table tr td {
  background-color: #fff;
}

.editEmail .form-control {
  min-height: 15px;
  /* border-color: transparent; */
  border-radius: 12px;
  /* background-color: transparent; */
  width: 200px;
  color: #000;
  /* padding: 0; */
  /* border: 0; */
  /* border-radius: 0; */
  font-family: "Graphik Regular";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #000000;
}

.editEmail .form-control::placeholder {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  font-style: normal;
  text-align: left;
  color: #9a9b9b;
}

.editEmail .form-control:focus {
  border: 1px solid #10b700;
}

.editEmail .UpdateButton {
  width: 100px;
  height: 40px;
  background-color: #10b700 !important;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 12px;
  border: none;
}

.editEmail .CancelButton {
  width: 100px;
  height: 40px;
  background-color: red !important;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 12px;
  border: none;
}

.UpdateButtonDeskTop {
  width: 170px !important;
  height: 45px !important;
  background-color: #10b700 !important;
  font-size: 14px !important;
  font-style: normal !important;
  text-align: center !important;
  color: #ffffff !important;
  border-radius: 12px !important;
  border: none !important;
  margin-left: auto;
}

.CancelButtonDeskTop {
  width: 170px !important;
  height: 45px !important;
  background-color: red !important;
  font-size: 14px !important;
  font-style: normal !important;
  text-align: center !important;
  color: #ffffff !important;
  border-radius: 12px !important;
  border: none !important;
}

.dateTd .AddMemberButton,
.dateTd .mamberDateSelectBox .react-datepicker-wrapper,
.dateTd .dropdown .memberDropDown,
.mainMemberBox .form-control {
  width: 100% !important;
}

.editExpiryDate .memberDropDown {
  min-height: 56px;
  border-color: transparent;
  border-radius: 12px;
  width: 100% !important;
  background-color: #f7f7f7;
  font-family: "Graphik Regular" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  font-style: normal !important;
  text-align: left !important;
  color: #000 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: start !important;
  padding: 12px 14px;
  border: 1px solid transparent;
}

.editExpiryDate .memberDropDown:hover {
  background-color: #f7f7f7;
}

.editExpiryDate .memberDropDown:active,
.editExpiryDate .memberDropDown:focus,
.editExpiryDate .memberDropDown:focus-visible {
  background-color: #f7f7f7 !important;
  border-color: #10b700 !important;
  outline: 0;
}

.editExpiryDate .dropdown .memberDropDown {
  color: #9a9b9b !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100% !important;
  overflow: hidden;
}

.editExpiryDate .dropdown .memberDropDown:hover {
  border-color: transparent;
}

.editExpiryDate .dropdown .memberDropDown:disabled,
.editExpiryDate .dropdown .memberDropDown.show {
  background-color: #f7f7f7 !important;
  border-color: transparent;
}

.editExpiryDate .dropdown .memberDropDown:focus-visible {
  box-shadow: none;
}

.editExpiryDate .dropdown .memberDropDown.selected {
  color: #000 !important;
}

.editExpiryDate .dropdown .dropdown-menu {
  padding: 10px 0;
  width: 250px;
  top: 60px;
  left: -10px;
  right: unset;
}

.editExpiryDate .dropdown .dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px 20px 20px;
}

.editExpiryDate .dropdown .dropdown-item span {
  font-family: "Graphik Regular";
  font-size: 14px;
  line-height: normal;
  font-style: normal;
  text-align: left;
  color: #06152b;
}

.copyBtn img {
  cursor: pointer;
  position: relative;
  top: -1px;
}

.copyBtn {
  position: relative;
  background-color: transparent;
  border: none;
}

.tooltip {
  position: absolute;
  top: -30px;
  /* Adjust based on your layout */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10;
}

.tooltip-inner {
  background-color: black !important;
  color: white !important;
  padding: 5px 10px;
  font-size: 12px;
}

.actionDropdown {
  position: relative;
}

.actionDropdown button {
  background-color: transparent;
  border: none;
}

.actionDropdown .dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1050;
  display: block;
  background: #000;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 150px;
  padding: 20px 0;
}

.actionDropdown .dropdown-item {
  font-family: "Graphik Regular";
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
  transition: 0.3s all ease;
}

.actionDropdown .dropdown-item:hover,
.actionDropdown .dropdown-item:focus {
  font-family: "Graphik Medium";
  color: #ffffff;
  background-color: #000;
}

.combineBox {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 30px;
}

.only-for-sm {
  display: none;
}

.dateTd .editExpiryDate .memberDropDown,
.combineBox .combineBoxInner .memberDropDown {
  border: 1px solid #10b700;
}

.combineBox .combineBoxInner .memberDropDown {
  color: #00000079;
  font-size: 10px;
  font-weight: 400;
}

.dateTd .editExpiryDate .memberDropDown:hover,
.combineBox .memberDropDown:hover {
  background-color: transparent;
  border: 1px solid #10b700;
}

.form-control.filterInput {
  font-size: 14px;
}

.mainMemberListBox .tableBox .codeTd .code .number-w {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #000000;
  width: 55px;
}

.codeStatus .imgContainer {
  display: flex;
  justify-content: center;
  width: 55px;
  text-align: center;
}

.codeStatus img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  object-position: center center;
}

.codeStatus .pending {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 0;
  width: 55px;
}

.mainMemberListBox .tableBox .statusTd .VipStatus,
.mainMemberListBox .tableBox .actionDropdown {
  width: 200px;
}

/* .tableBox .table-responsive table tr th, */
.tableBox .table-responsive table tr td {
  height: 90px;
  vertical-align: middle;
}

@media only screen and (min-width: 1100px) and (max-width: 1290px) {
  .UpdateButtonDeskTop {
    width: 120px !important;
    margin-left: auto;
  }

  .CancelButtonDeskTop {
    width: 120px !important;
  }

  .mainMemberListBox .tableBox .statusTd .VipStatus,
  .mainMemberListBox .tableBox .actionDropdown {
    width: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1099px) {
  .UpdateButtonDeskTop {
    width: 100px !important;
    margin-left: auto;
  }

  .CancelButtonDeskTop {
    width: 100px !important;
  }

  .mainMemberListBox .tableBox .code {
    gap: 10px;
  }

  .mainMemberListBox .tableBox .statusTd .VipStatus,
  .mainMemberListBox .tableBox .actionDropdown {
    width: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .UpdateButtonDeskTop {
    width: 80px !important;
    margin-left: auto;
  }

  .CancelButtonDeskTop {
    width: 80px !important;
  }

  .mainMemberListBox .tableBox .code {
    gap: 10px;
  }

  .mainMemberListBox .tableBox .statusTd .VipStatus,
  .mainMemberListBox .tableBox .actionDropdown {
    width: 80px;
  }

  .mainMemberListBox .tableBox .emailTh {
    width: 20%;
  }

  /* .tableBox .table-responsive table tr th,
  .tableBox .table-responsive table tr td {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  } */
}

@media only screen and (max-width: 768px) {
  .mainMemberListBox .tableBox .statusTd .VipStatus,
  .mainMemberListBox .tableBox .actionDropdown {
    width: auto !important;
  }
}

@media only screen and (max-width: 640px) {
  .mainMemberListBox .tableBox thead th {
    display: none;
  }

  .mainMemberListBox .tableBox .dateTd,
  .mainMemberListBox .tableBox .dateTh,
  .mainMemberListBox .tableBox .statusTh {
    display: none;
  }

  .mainMemberListBox .tableBox .codeTd,
  .mainMemberListBox .tableBox .codeTh,
  .mainMemberListBox .tableBox .statusTd {
    display: none;
  }

  .mainMemberListBox .tableBox .code {
    gap: 15px;
  }

  .only-for-sm {
    display: flex;
  }

  .mainMemberListBox .tableBox .title {
    opacity: 0.5;
    font-family: "Graphik Regular";
    font-size: 12px;
    text-align: left;
    color: #000000;
  }

  .combineBox {
    gap: 20px;
  }

  .mainMemberListBox .tableBox .code,
  .mainMemberListBox .tableBox .date,
  .mainMemberListBox .tableBox .mail {
    font-family: "Graphik Regular";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #000000;
  }

  .combineBox .combineBoxInner .dropdown .dropdown-menu {
    width: 250px;
    /* height: 145px; */
    right: unset;
    left: -10px;
    padding: 10px 0;
  }

  .combineBox .combineBoxInner .dropdown .dropdown-menu .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 30px 20px 20px;
  }

  .combineBox .combineBoxInner .dropdown .dropdown-menu .dropdown-item span {
    font-family: "Graphik Regular";
    font-size: 14px;
    line-height: normal;
    font-style: normal;
    text-align: left;
    color: #06152b;
  }

  .codeStatus .imgContainer {
    width: 30px;
  }
}

#deleteModal {
  width: 352px;
  /* height: 277px;
  overflow: hidden; */
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.1));
}

#deleteModal .modal-content {
  border-color: #d6d6d6;
  border-width: 0.5px;
  border-style: solid;
  background: #ffffff;
  margin-top: 100px;
  /* height: 277px; */
}

#deleteModal .modal-body {
  padding: 0;
}

#deleteModal .modalContent {
  display: block;
  align-items: center;
  justify-content: start;
  /* gap: 20px; */

  position: relative;
}

#deleteModal .modalContent .regBox {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 42px 30px 0px 5px;
}

#deleteModal .modalContent .closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0 !important;
  background-color: transparent;
  border: none;
  width: fit-content;
  height: auto;
}

#deleteModal .modalContent .closeBtn img {
  width: 15px;
  height: 15px;
}

#deleteModal .modalContent .content-box p {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-style: normal;
  text-align: left;
  color: #06152b;
}

#deleteModal .modalContent .content-box p span {
  font-family: "Graphik Medium";
  font-size: 14px;
  font-style: normal;
  text-align: left;
  color: #06152b;
}

#deleteModal .modalContent .LoadingBox {
  text-align: center;
  height: 176px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#deleteModal .modalContent .LoadingBox img {
  height: 80px;
}

#deleteModal .buttonDiv {
  padding-bottom: 26px;
}

.DeleteMemberButton {
  width: 156px;
  padding: 10px 30px;
  height: 46px;
  background-color: #000;
  color: #fff;
  font-family: "Graphik Regular";
  font-size: 14px;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 12px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.DeleteMemberButton:hover,
.DeleteMemberButton:active {
  background-color: #10b700 !important;
}

.custom-dropdown.z-index-fix .dropdown-toggle {
  z-index: 0;
}

@media only screen and (max-width: 576px) {
  .mainMemberListBox {
    padding: 60px 20px 50px 20px;
  }

  /* .custom-dropdown.z-index-fix {
    z-index: 0!important;
  } */

  #deleteModal {
    margin: 0 auto;
  }

  #deleteModal .modalContent {
    gap: 0;
  }

  #deleteModal .modalContent .regBox {
    padding: 40px 10px 0px 10px;
  }

  #deleteModal .modalContent .content-box p {
    font-size: 12px;
  }

  #deleteModal .modalContent .img-box img {
    width: 80px;
  }
}

@media only screen and (min-width: 368px) and (max-width: 480px) {
  .combineBoxInner {
    width: 100px;
  }
}

@media only screen and (max-width: 368px) {
  .combineBox {
    display: block;
  }

  .combineBox div:first-of-type {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 360px) {
  #deleteModal {
    width: 95%;
    margin: 0 auto;
  }
}
