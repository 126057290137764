.signinWhitebtn {
  background-color: #fff;
  border: 1px solid #c6c6c6;
  filter: drop-shadow(0px 27px 12px rgba(0, 0, 0, 0.05));
  width: 100%;
  border-radius: 8px;
  position: relative;
  padding: 14px 20px 14px 30px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 30px;
}
.signinWhitebtn .toptag {
  position: absolute;
  top: -10px;
  right: 10px;
  background-color: #000;
  color: #fff;
  padding: 0px 10px;
  font-size: 12px;
  display: block;
  border-radius: 5px;
  text-transform: uppercase;
}

.signinWhitebtn img {
  width: 27px;
  height: 27px;
  object-fit: contain;
  object-position: center center;
}

.signinWhitebtn .text {
  font-family: "Graphik Medium";
  font-size: 17px;
  font-style: normal;
  text-align: left;
  color: #000000;
  display: flex;
  width: 200px;
  margin-bottom: 0 !important;
}
