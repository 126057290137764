.dashboardStatusBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mainDashboardPage .minddleBorder {
  width: 1px;
  height: 100%;
  background-color: #f0f2f2;
}

.padingTop {
  padding-top: 65px;
}
