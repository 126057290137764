/*------------------------------------------------------------------
[Responsive Stylesheet]

Project     : p1-TradingHtml
Version     : 1.0
Author      : Md Ekramul Hassan Avi
Author URI  : https://www.tigertemplate.com
-------------------------------------------------------------------*/

@media (max-width: 1400px) and (min-width: 992px) {
    .landing_section .fs40.li_h40 {
        font-size: 20px;
        line-height: 20px;
    }
    .landing_section .fs30 {
        font-size: 18px;
    }
    .landing_img {
        width: 80%;
    }
}
@media screen and (max-width: 1199.99px) {
    .back_link {
        left: 20px;
        top: 6px;
    }
}
@media screen and (max-width: 991.99px) {
    .border_sm_none {
        border: 0 !important;
    }
    .back_link {
        left: -20px;
        top: 6px;
    }
}
@media screen and (max-width: 576px) {
    .graph_info .fs18 {
        font-size: 13px;
    }
    .graph_info .fs57 {
        font-size: 40px;
    }
    /* .w_261 {
    width: 100%;
    max-width: 100%;
  } */
    .back_link {
        left: 20px;
        top: -20px;
    }
    .modal-sm {
        max-width: 100%;
    }

    /* .common_style {
        min-height: 100vh !important;
        display: flex;
        flex-direction: column;
    } */
    .site_footer {
        text-align: center;
        position: fixed;
        width: 91%;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        margin: 0 auto;
        padding-bottom: 10px;
    }
    /* .main_body .h-100 {
        min-height: 520px;
    } */
    .mt-sm-auto.mt-5 {
        margin-top: auto !important;
    }
    .common_style .tabs_list.fs25 {
        font-size: 18px;
    }
    .page_form {
        padding-top: 30px;
    }
    .text-box-wrapper {
        width: 100%;
        height: 100%;
    }
    .modal-dialog {
        margin: 0 30px;
    }

    .slider_owl:after {
        width: calc(100% - 96px);
    }
    .slide_title {
        min-height: 350px;
    }
    .landing_section .fs40 {
        font-size: 30px;
        line-height: 30px;
    }
}

@media screen and (max-width: 400px) {
    .connect_box p.text_dark6 {
        font-size: 11px;
    }
}

@media screen and (max-width: 600px) {
    .support_wrapper {
        height: calc(100vh - 100px) !important;
        position: relative;
        min-height: unset !important;
    }
    .support_wrapper .h-100 {
        min-height: unset !important;
    }
}

@media screen and (min-width: 1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1170px;
    }
}

@media (max-width: 1930px) {
    .loader {
        transform: scale(1.2) !important;
        overflow-y: hidden;
    }
    .Toastify {
        transform: none !important;
    }
    /* #root > * {
        transform: scale(0.87) translateY(-50px);
    } */
    .slide_img1 {
        top: 0;
    }
    .slide_img3 {
        top: 65px;
    }
    .slide_img4 {
        top: 60px;
    }
    .slide_title {
        min-height: 300px;
    }
    .slider_content {
        min-height: 125px;
    }
}
/* @media (max-width: 1000px) {
    #root > * {
        transform: none;
    }
} */
