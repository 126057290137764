/* .newLayoutBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;

}

.mainContentBox {
    width: 100%;
    padding: 50px;
}

.sideBarBox {
    width: 304px;
    display: flex;
    unicode-bidi: unset;
}

@media only screen and (min-width: 1440px) {
    .sideBarBox {
        width: 310px;
        min-height: 100vh;
    }
}

@media only screen and (min-width: 1700px) {
    .sideBarBox {
        width: 297px;
        min-height: 100vh;
    }
}

@media only screen and (max-width: 945px) {
    .sideBarBox {
        width: 0;
    }
}

@media only screen and (max-width: 576px) {
    .mainContentBox {
        padding: 15px;
    }
} */


.newLayoutBox {
    display: flex;
    /* Arrange children (sidebar and main content) side by side */
    height: 100vh;
    /* Make the layout take up the full height of the viewport */
}

.sideBarBox {
    width: 247px;
    /* Fixed width for the sidebar */
    position: fixed;
    /* Keep the sidebar fixed while scrolling */
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    /* Sidebar should cover the full height of the viewport */
    background-color: #f4f4f4;
    /* Example background color */
    border-right: 1px solid #ccc;
    /* Optional: A border to separate the sidebar */
}

.mainContentBox {
    margin-left: 247px;
    /* Offset the main content to the right of the sidebar */
    padding: 50px;
    /* Add the 50px padding around the main content */
    flex: 1;
    /* Allow the main content to occupy all remaining space */
    background-color: #f8f9fa;
    /* Example background color */
    overflow-y: auto;
    /* Enable scrolling if content overflows */
}


@media only screen and (min-width: 1440px) {
    .sideBarBox {
        width: 253px;
    }

    .mainContentBox {
        margin-left: 253px;
    }

    .mainContentBox .container-fluid {
        max-width: 1440px;
    }
}


@media only screen and (max-width: 945px) {
    .sideBarBox {
        width: 0;
    }

    .mainContentBox {
        margin-left: 0;
    }
}

@media only screen and (max-width: 576px) {
    .mainContentBox {
        padding: 16px;
    }

    .mt-smLayout-30 {
        margin-top: 30px;
    }
}