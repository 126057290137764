.addMembeBox {
  background-color: #dfe3ea;
  padding: 60px 40px 125px 40px;
  border-radius: 10px;
  margin-bottom: 50px;
}

.addMembeBox .title {
  font-family: "Graphik Medium";
  font-size: 16px;
  line-height: normal;
  font-style: normal;
  text-align: left;
  color: #06152b;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
}

.addMembeBox .title .infoIcon {
  cursor: pointer;
}

.addMembeBox .title span img {
  width: 20.9px;
  height: 20.9px;
  object-fit: contain;
  object-position: center center;
}

.mainMemberBox .form-control {
  min-height: 56px;
  border-color: transparent;
  border-radius: 12px;
  background-color: #f7f7f7;
  font-size: 14px;
  font-family: "Graphik Regular";
  font-weight: 500;
  color: #000;
}

.mainMemberBox .form-control::placeholder {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  font-style: normal;
  text-align: left;
  color: #9a9b9b;
}

.mainMemberBox .form-control:focus {
  border: 1px solid #10b700;
}

.mainMemberBox .memberDropDown {
  min-height: 56px;
  border-color: transparent;
  border-radius: 12px;
  width: 100% !important;
  background-color: #f7f7f7;
  font-family: "Graphik Regular" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
  font-style: normal !important;
  text-align: left !important;
  color: #000 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: start !important;
  padding: 12px 14px;
  border: 1px solid transparent;
  overflow: hidden;
}

.mainMemberBox .memberDropDown:hover {
  background-color: #f7f7f7;
}

.mainMemberBox .memberDropDown:active,
.mainMemberBox .memberDropDown:focus,
.mainMemberBox .memberDropDown:focus-visible {
  background-color: white !important;
  border-color: #10b700 !important;
  outline: 0;
}

.mainMemberBox .dropdown .memberDropDown {
  color: #9a9b9b !important;
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 0;
}

.mainMemberBox .dropdown .memberDropDown:hover {
  border-color: transparent;
}

.mainMemberBox .dropdown .memberDropDown:disabled,
.mainMemberBox .dropdown .memberDropDown.show {
  background-color: #f7f7f7 !important;
  border-color: transparent;
}

.mainMemberBox .dropdown .memberDropDown:focus-visible {
  box-shadow: none;
}

.mainMemberBox .dropdown .memberDropDown.selected {
  color: #000 !important;
}

.mainMemberBox .dropdown .dropdown-menu {
  padding: 10px 0;
  width: 250px;
  top: 60px;
  left: -10px;
  right: unset;
}

.mainMemberBox .dropdown .dropdown-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px 20px 20px;
}

.mainMemberBox .dropdown .dropdown-item span {
  font-family: "Graphik Regular";
  font-size: 14px;
  line-height: normal;
  font-style: normal;
  text-align: left;
  color: #06152b;
}

.mamberDateSelectBox .react-datepicker-wrapper {
  width: 100%;
}

.mainMemberBox .AddMemberButton {
  width: 100%;
  padding: 10px 30px;
  height: 56px;
  background-color: #000;
  color: #fff;
  font-family: "Graphik Regular";
  font-size: 14px;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 12px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.mainMemberBox .AddMemberButton:hover,
.mainMemberBox .AddMemberButton:active {
  background-color: #10b700 !important;
}

.infoPopup {
  padding: 28px 20px 20px 20px;
  border-color: #c6c6c6;
  border-width: 0.20000000298023224px;
  border-style: solid;
  border-radius: 10px;
  width: 240px;
  /* height: 180px; */
  filter: drop-shadow(-5px 15px 10px rgba(0, 0, 0, 0.2));
  background: #000;
  position: absolute;
  top: 24px;
  left: 100%;
  z-index: 100;
}

.infoPopup button {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 7px;
  right: 15px;
}

.infoPopup button img {
  width: 9px;
  height: 9px;
}

.infoPopup p,
.infoPopup p span {
  font-family: "Graphik Regular";
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #ffffff;
}

/* .modal.show {
  background-color: transparent !important;
} */

#addMemberModal {
  width: 352px;
  /* height: 277px;
  overflow: hidden; */
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.1));
}

#addMemberModal .modal-content {
  border-color: #d6d6d6;
  border-width: 0.5px;
  border-style: solid;
  background: #ffffff;
  margin-top: 100px;
  /* height: 277px; */
}

#addMemberModal .modal-body {
  padding: 0;
}

#addMemberModal .modalContent {
  display: block;
  align-items: center;
  justify-content: start;
  /* gap: 20px; */

  position: relative;
}

#addMemberModal .modalContent .regBox {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 42px 30px 36px 5px;
}

#addMemberModal .modalContent .closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 0 !important;
  background-color: transparent;
  border: none;
  width: fit-content;
  height: auto;
}

#addMemberModal .modalContent .closeBtn img {
  width: 15px;
  height: 15px;
}

#addMemberModal .modalContent .content-box p {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-style: normal;
  text-align: left;
  color: #06152b;
}

#addMemberModal .modalContent .content-box p span {
  font-family: "Graphik Medium";
  font-size: 14px;
  font-style: normal;
  text-align: left;
  color: #06152b;
}

#addMemberModal .modalContent .LoadingBox {
  text-align: center;
  height: 176px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#addMemberModal .modalContent .LoadingBox img {
  height: 80px;
}

.errContainer {
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  border-color: #c6c6c6;
  border-width: 0.2px;
  border-style: solid;
  border-radius: 10px;
  width: 233px;
  filter: drop-shadow(-5px 15px 10px rgba(0, 0, 0, 0.2));
  background: #000;
  color: white;
  z-index: 101;
}

.errContainer p {
  padding: 47px 20px;
  font-family: "Graphik Medium";
  font-size: 12px;
  font-style: normal;
  font-weight: 500px;
}

/* Main Page  Heading */
.PageHeading h2 {
  padding-left: 40px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 1440px) {
  .mainMemberBox .AddMemberButton,
  .mamberDateSelectBox .react-datepicker-wrapper,
  .mainMemberBox .dropdown .memberDropDown,
  .mainMemberBox .form-control {
    width: 215px !important;
  }
}

@media only screen and (max-width: 992px) {
  .addMembeBox {
    padding: 60px 40px 88px 40px;
  }
}

@media only screen and (max-width: 576px) {
  .addMembeBox {
    padding: 60px 20px 60px 20px;
  }

  .infoPopup {
    left: 0;
  }

  .addMembeBox {
    margin-bottom: 30px;
  }

  #addMemberModal {
    margin: 0 auto;
  }

  #addMemberModal .modalContent {
    gap: 0;
  }

  #addMemberModal .modalContent .regBox {
    padding: 40px 10px;
  }

  #addMemberModal .modalContent .content-box p {
    font-size: 12px;
  }

  #addMemberModal .modalContent .img-box img {
    width: 80px;
  }

  /* Main Page  Heading */
  .PageHeading h2 {
    padding-left: 20px;
  }

  .mainVipMemberPage .container-fluid {
    padding: 0;
  }
}

@media only screen and (max-width: 360px) {
  #addMemberModal {
    width: 95%;
    margin: 0 auto;
  }
}

/* @media only screen and (min-width: 993px) and (max-width: 1107px) {

  .mainMemberBox .form-control::placeholder,
  .mainMemberBox .form-control {
    font-size: 10px;
  }

  .mainMemberBox .memberDropDown {
    font-size: 10px !important;
  }
} */
