/* Start General CSS */
.CP-fs-19 {
  font-size: 19px;
}

.CP-fs-20 {
  font-size: 20px;
}

.CP-fs-25 {
  font-size: 25px;
}

.CP-fs-12 {
  font-size: 12px;
}

.CP-fs-14 {
  font-size: 14px;
}

.CP-fs-18 {
  font-size: 18px;
}

.CP-fs-15 {
  font-size: 15px;
}

.fw-regular {
  font-family: "Graphik Regular", sans-serif;
}

.fw-medium {
  font-family: "Graphik Medium", sans-serif;
}

.fw-bold {
  font-family: "Graphik Bold", sans-serif;
}

.text-green {
  color: #0fb700;
}

.text-red {
  color: #ff0202;
}

.nowrap {
  white-space: nowrap;
}

.greenArrowBtn {
  background-color: #0fb700;
  color: #fff;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50rem;
}

.greenArrowBtn img {
  filter: brightness(2);
  width: 18px;
  margin-top: -4px;
  margin-left: 5px;
  transition: 0.3s all ease;
}

.greenArrowBtn:hover {
  display: flex;
  background-color: white;
  filter: none;
  transform: rotate(45deg);
}

.greenArrowBtn:hover img {
  filter: none;
  width: 18px;
  margin-top: -4px;
  margin-left: 5px;
}

.SimpleBlackBtn {
  background-color: #000;
  color: #fff;
  border-radius: 7px;
  padding: 7px 15px 6px 15px;
  font-family: "Graphik Medium", sans-serif;
}

.SimpleBlackBtn:hover {
  color: #fff;
  background-color: #0fb700;
}

/* End General CSS */

.mainDashboardStats {
  padding-top: 65px;
}

.mainDashboardStats .statsCard {
  border-color: #70707025;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  filter: drop-shadow(0px 3px 3px rgba(39, 45, 59, 0.16));
  background: #ffffff;
  padding: 42px 26px;
  margin-bottom: 20px;
  width: 100%;
  min-height: 173px;
  overflow: hidden;
  position: relative;
}

.mainDashboardStats .downloadCard {
  border-color: #70707025;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  filter: drop-shadow(0px 3px 3px rgba(39, 45, 59, 0.16));
  background: #ededed;
  padding: 15px 26px;
  margin-bottom: 20px;
  width: 100%;
  min-height: 173px;
  overflow: hidden;
  position: relative;
}

.mainDashboardStats .statsCard.black {
  background-color: #000;
  color: #fff;
}

.mainDashboardStats .statsCard.gray {
  background-color: #ededed;
}

.mainDashboardStats .statsCardSmall {
  border-color: #70707025;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  filter: drop-shadow(0px 3px 3px rgba(39, 45, 59, 0.16));
  background: #ffffff;
  padding: 16px 0 19px 0;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.mainDashboardStats .statsCardSmall.black {
  background-color: #000;
  color: #fff;
}

.tradeGraphImage {
  position: absolute;
  width: 100%;
  height: auto;
  padding: 42px 26px;
  top: 18%;
  left: 0;
}

.mainDashboardStats .tradeHistory .greenArrowBtn {
  position: absolute;
  bottom: 15px;
  right: 20px;
}

.mainDashboardStats .SubscriberLeght {
  min-height: 181px;
}

.mainDashboardStats .paddingBtmFix {
  padding-bottom: 18px;
}

.mainDashboardStats .PercentImg {
  width: 80px;
}

.mainDashboardStats .fixpaddingGray {
  padding-bottom: 34px;
}

.mainDashboardStats .mbls {
  width: 129px;
  position: absolute;
  right: 0px;
  top: 10px;
}

.new_launch {
  width: 100px;
  transform: rotate(45deg);
}

.mainDashboardStats .DailyAvg {
  width: 87px;
}

.mainDashboardStats .longCard {
  min-height: auto;
}

.mainDashboardStats .longCard .SimpleBlackBtn {
  width: 130px;
  text-align: center;
}

@media only screen and (min-width: 1484px) and (max-width: 1752px) {
  .mainDashboardStats .firstCard .desc,
  .mainDashboardStats .firstCard .title,
  .mainDashboardStats .secondCard .desc,
  .mainDashboardStats .secondCard .title {
    white-space: nowrap;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1529px) {
  .mainDashboardStats .mbls {
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .mainDashboardStats {
    padding-top: 17px;
  }
}
